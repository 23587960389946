
import React from 'react';
import ReactDOM from 'react-dom';

// Import bootstrap
import 'bootstrap/dist/css/bootstrap.css';

// Import custom style sheet
import './index.css';

const element = (
	<div class="container h-100">
		<div class="row h-100 justify-content-center align-items-center">
			<div class="col-8 text-center">
				Good news, everyone!
			</div>
		</div>
	</div>
);

ReactDOM.render(element, document.getElementById('root'));